// TODO: FIX ERROR
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-call */
import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react';
import {
  onAuthStateChanged,
  getAuth,
  signInWithEmailAndPassword,
  User,
} from 'firebase/auth';
import { firebaseAuth } from '../utils/firebase/config';
import axios, { AxiosError } from 'axios';
import {
  UidAccountMapOutput,
  CreateUserInput,
  CreateAlphaUserInput,
  RecentPersonaInput,
} from '../types/API';
import { useRouter } from 'next/router';
import * as FullStory from '@fullstory/browser';
import useSWR from 'swr';
import { UserData } from '../types/User.type';
import { useAuth0 } from '@auth0/auth0-react';
import { doc, updateDoc } from 'firebase/firestore';
import { adminDb } from '../utils/firebase/adminConfig';

interface AuthContextInterface {
  attemptedSignIn: boolean;
  isSignedIn: boolean;
  authUser: User | undefined;
  userData: UserData | undefined;

  createUser: ({
    newUserData,
    creationByAdmin,
  }: {
    newUserData: CreateUserInput;
    creationByAdmin?: boolean;
  }) => Promise<{ uid?: string; error?: string }>;

  executeAfterUserSignedIn: (
    user: User,
    createAlphaUser?: boolean,
    specificRedirectHref?: string
  ) => Promise<void>;
  signOutUser: () => Promise<void>;
  signInUser: (password: string) => Promise<void | { error?: string }>;
  refetchUserData: () => Promise<void>;
  logRecentPersonaData: ({
    RecentBrand,
    RecentProfilePic,
    RecentDescription,
    RecentWebsite,
    RecentTone,
    RecentIndustry,
    RecentAudience,
    RecentAudienceProfilePic,
    RecentPainpoints,
    RecentLocation,
    RecentOccupation,
    RecentAge,
    RecentAudienceGoals,
    RecentAudienceTriggers,
    RecentAudienceBarriers,
    RecentProduct,
    RecentProductDescription,
    RecentBrandAvoidArray,
    RecentBrandReplacementArray,
    RecentBrandSparinglyArray,
  }: RecentPersonaInput) => Promise<void>;
}

const AuthContext = createContext<AuthContextInterface | undefined>(undefined);

export const AuthContextProvider = ({ children }: { children: ReactNode }) => {
  const router = useRouter();
  const queryInviteCode = router.query.inviteCode as string;
  const { logout } = useAuth0();
  const [attemptedSignIn, setAttemptedSignIn] = useState<boolean>(false);
  const [isSignedIn, setIsSignedIn] = useState<boolean>(false);
  const [authUser, setAuthUser] = useState<User>();

  const { data: userData, mutate: mutateUserData } = useSWR<UserData>(
    authUser ? '/api/user/getUser' : null
  );

  const executeAfterUserSignedIn = async (
    user: User,
    createAlphaUser?: boolean,
    specificRedirectHref?: string
  ) => {
    console.log('---Signed In---');

    // Set authorization token
    axios.defaults.headers.common['fbtkn'] = await user.getIdToken(true);
    setAttemptedSignIn(false);
    setIsSignedIn(true);
    setAuthUser(user);
    FullStory.identify(user.uid, {
      email: user.email || 'No email provided',
      displayName: user.displayName || 'No displayName provided',
    });
    // mixpanel.identify(user.uid);
    // mixpanel.people.set_once({
    //   displayName: user.displayName,
    //   email: user.email,
    //   $email: user.email,
    //   $name: user.displayName,
    //   $distinct_id: user.uid,
    // });
    // mixpanel.track('Sign in');

    if (createAlphaUser) {
      await _createAlphaUser({
        uid: user.uid,
        email: user.email || '',
        displayName: user.displayName || undefined,
        inviteCode: queryInviteCode,
      });
    }

    // Check if onboarding needs to be complete

    // Get user data if necessary
    let tempUserData = userData;
    if (!tempUserData) {
      try {
        tempUserData = (await axios.get<UserData>('/api/user/getUser')).data;
        mutateUserData(tempUserData, {
          revalidate: false,
        });
      } catch (error) {
        console.log(error);
      }
    }

    if (specificRedirectHref) {
      console.log('specificredirect act');
      router.push(specificRedirectHref);
    } else if (
      tempUserData &&
      (!tempUserData?.stripe || !tempUserData.stripe.subscription)
    ) {
      router.push('/signin/checkout');
    } else if (
      tempUserData &&
      !tempUserData?.finishedOnboarding &&
      !router.route.includes('/signin/checkout')
    ) {
      // User needs to finish onboarding
      if (router.route !== '/' && router.route !== '/signin/onboarding') {
        router.push('/signin/onboarding');
      }
    } else if (router.query.redirect) {
      if (
        router.query.redirect === '/signin' ||
        router.query.redirect === '/signout' ||
        router.query.redirect === '/'
      ) {
        router.push('/dashboard');
      } else {
        router.push(router.query.redirect as string);
      }
    } else if (router.route === '/') {
      router.push('/dashboard');
    } else if (router.route === '/admin') {
      router.push('/admin');
    } else if (router.route === '/personas') {
      router.push('/personas');
    } else if (router.route === '/settings') {
      router.push('/settings');
    } else if (router.route === '/templates') {
      router.push('/templates');
    }

    // else if (router.route === '/signin' && tempUserData.stripe.subscription && tempUserData.subscribed) {
    //   router.push('/dashboard');
    // }
    else if (router.route.includes('/editor') 
      || router.route.includes('/superChat') 
      || router.route.includes('/brandAutomation') 
      || router.route.includes('/personaAutomation')) {
      router.push(router.asPath);
    } else {
      router.push('/dashboard');
    }

    setAttemptedSignIn(true);
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(firebaseAuth, (user) => {
      if (user) {
        executeAfterUserSignedIn(user);
        // Refresh user token after 56min of activity
        const refreshToken = setInterval(() => {
          user
            .getIdToken()
            .then(() => executeAfterUserSignedIn(user))
            .catch((err) => console.log(err));
        }, 1000 * 60 * 60);
        return () => {
          clearInterval(refreshToken);
        };
      } else {
        setAttemptedSignIn(true);
      }
    });
    return unsubscribe;
  }, [firebaseAuth]);

  useEffect(() => {
    const updateLastSignIn = async () => {
      if (authUser) {
        try {
          await axios.post('/api/user/updateUserLastSignIn');
        } catch (error) {
          // handle error
          console.log(error);
        }
      }
    };

    updateLastSignIn();
  }, [authUser]);

  const createUser = async ({
    newUserData,
    creationByAdmin,
  }: {
    newUserData: CreateUserInput;
    creationByAdmin?: boolean;
  }): Promise<{ uid: string } | { error: string }> => {
    try {
      const { data: createUserAxiosData } = await axios.post<string>(
        '/api/user/createUser',
        newUserData
      );
      if (!creationByAdmin) {
        await signInWithEmailAndPassword(
          firebaseAuth,
          newUserData.email,
          newUserData.password
        );
      }
      return { uid: createUserAxiosData };
    } catch (err) {
      console.log(err);
      const error = err as AxiosError;
      return {
        error: error.response?.data
          ? // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            (error.response.data?.error as string)
          : '',
      };
    }
  };

  // const changeUserEmail = async ({
  //   newUserData,
  //   creationByAdmin,
  // }: {
  //   newUserData: CreateUserInput;
  //   creationByAdmin?: boolean;
  // }): Promise<{ uid: string } | { error: string }> => {
  //   try {
  //     const auth = getAuth();
  //     await updateUser(newUserData.uid, {
  //       email: newUserData.email,
  //   }} catch (err) {
  //     console.log(err);
  //     const error = err as AxiosError;
  //     return {
  //       error: error.response?.data
  //         ? // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //           // @ts-ignore
  //           (error.response.data?.error as string)
  //         : '',
  //     };
  //   }
  // };

  const _createAlphaUser = async (
    createAlphaUserInput: CreateAlphaUserInput
  ) => {
    await axios.post('/api/auth/createAlphaUser', createAlphaUserInput);
  };

  const signOutUser = async () => {
    try {
      await getAuth().signOut();
      await logout({
        logoutParams: { returnTo: 'https://www.app.supercopy.ai' },
      });
      setAttemptedSignIn(true);
      setIsSignedIn(false);
      mutateUserData(undefined, {
        revalidate: false,
      });
      console.log('---Signed Out---');
    } catch (error) {
      console.log(error);
    }
  };

  const signInUser = async (
    password: string
  ): Promise<void | { error: string }> => {
    try {
      const uid = password.split('_')[0];
      const realPassword = password.split('_').slice(1).join('');
      const { data: uidAccountMapAxiosData } =
        await axios.post<UidAccountMapOutput>('/api/auth/getEmail', { uid });
      const uidAccountMapEmail = uidAccountMapAxiosData.email;
      await signInWithEmailAndPassword(
        firebaseAuth,
        uidAccountMapEmail,
        realPassword
      );
    } catch (error) {
      console.log(error);
      return { error: 'Wrong password, please try again' };
    }
  };

  const refetchUserData = async () => {
    await mutateUserData();
  };

  const logRecentPersonaData = async (recentData: RecentPersonaInput) => {
    const {
      RecentBrand,
      RecentProfilePic,
      RecentDescription,
      RecentWebsite,
      RecentTone,
      RecentIndustry,
      RecentAudience,
      RecentAudienceProfilePic,
      RecentPainpoints,
      RecentLocation,
      RecentOccupation,
      RecentAge,
      RecentAudienceGoals,
      RecentAudienceTriggers,
      RecentAudienceBarriers,
      RecentProduct,
      RecentProductDescription,
      RecentBrandAvoidArray,
      RecentBrandReplacementArray,
      RecentBrandSparinglyArray,
    } = recentData;

    try {
      await axios.post('/api/personas/logRecentPersonaData', {
        RecentBrand,
        RecentProfilePic,
        RecentDescription,
        RecentWebsite,
        RecentTone,
        RecentIndustry,
        RecentAudience,
        RecentAudienceProfilePic,
        RecentPainpoints,
        RecentLocation,
        RecentOccupation,
        RecentAge,
        RecentAudienceGoals,
        RecentAudienceTriggers,
        RecentAudienceBarriers,
        RecentProduct,
        RecentProductDescription,
        RecentBrandAvoidArray,
        RecentBrandReplacementArray,
        RecentBrandSparinglyArray,
      });
    } catch (err) {
      console.log(err);
      const error = err as AxiosError;
    } finally {
      await mutateUserData();
    }
  };

  return (
    <AuthContext.Provider
      value={{
        attemptedSignIn,
        isSignedIn,
        authUser,
        userData,

        createUser,
        executeAfterUserSignedIn,
        signOutUser,
        signInUser,
        refetchUserData,
        logRecentPersonaData,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuthContext = (): AuthContextInterface => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('AuthContext must be within AuthContextProvider');
  }

  return context;
};
