import axios, { AxiosError } from 'axios';
import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react';
import { APIErrors } from '../types/API';
import { FormType, GeneratedResponse } from '../types/FormTypes';
import { useFormContext } from './FormContext';
import { marked } from 'marked';

type TextSelection = {
  start: number;
  end: number;
};

type XYPosition = {
  x: number;
  y: number;
};

interface OutputAreaContextInterface {
  responseRated: boolean;
  setResponseRated: Dispatch<SetStateAction<boolean>>;
  successMessage: string;
  setSuccessMessage: Dispatch<SetStateAction<string>>;

  editTextSelected?: TextSelection | undefined;
  setEditTextSelected: Dispatch<SetStateAction<TextSelection | undefined>>;
  textareaScrollOffset: number;
  setTextareaScrolloffset: Dispatch<SetStateAction<number>>;
  revisePopupPosition: XYPosition | undefined;
  setRevisePopupPosition: Dispatch<SetStateAction<XYPosition | undefined>>;

  insertText: () => Promise<void>;

  copyResponse: (responseIndex?: number) => Promise<void>;
  fileToBeSaved: string;
  setFileToBeSaved: Dispatch<SetStateAction<string>>;
  showFileTree: boolean;
  setShowFileTree: Dispatch<SetStateAction<boolean>>;
  showReasoning: boolean;
  setShowReasoning: Dispatch<SetStateAction<boolean>>;
  reasoningID: string;
  setReasoningID: Dispatch<SetStateAction<string>>;
  saveFileClicked: (id: string) => void;
  reasoningClicked: (id: string) => Promise<string | undefined>;
}

const OutputAreaContext = createContext<OutputAreaContextInterface | undefined>(
  undefined
);

export const OutputAreaContextProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const {
    formType,
    blogInput,
    personaBlogInput,
    emailInput,
    personaEmailInput,
    socialInput,
    personaSocialInput,
    tiktokCaptionInput,
    marketingIdeasInput,
    personaMarketingIdeasInput,
    personaNewsletterInput,
    contentImproverInput,
    audienceRetargetInput,
    featureDescriptionInput,
    personaFeatureDescriptionInput,
    callToActionInput,
    personaCallToActionInput,
    heroHeaderInput,
    personaHeroHeaderInput,
    subheaderInput,
    personaSubheaderInput,
    audienceChangerInput,
    formatChangerInput,
    toneChangerInput,
    pasFrameworkInput,
    seasonalHolidayInput,
    discountPromotionInput,
    giveawayPromotionInput,
    linkedinCompanyBioInput,
    youtubeChannelDescriptionInput,
    youtubeVideoDescriptionInput,
    youtubeVideoScriptInput,
    shortVideoScriptInput,
    productDescriptionInput,
    valuePropInput,
    brandMissionInput,

    editedOutput,
    setEditedOutput,
    generatedOutput,
    setGeneratedOutput,
    setCanKeepGoing,
    setLoading,
  } = useFormContext();

  const [responseRated, setResponseRated] = useState<boolean>(false);
  const [successMessage, setSuccessMessage] = useState<string>('');
  const [fileToBeSaved, setFileToBeSaved] = useState<string>('');
  const [showFileTree, setShowFileTree] = useState<boolean>(false);
  const [showReasoning, setShowReasoning] = useState<boolean>(false);
  const [reasoningID, setReasoningID] = useState<string>('');

  const [editTextSelected, setEditTextSelected] = useState<
    TextSelection | undefined
  >();
  const [textareaScrollOffset, setTextareaScrolloffset] = useState<number>(0);
  const [revisePopupPosition, setRevisePopupPosition] = useState<
    XYPosition | undefined
  >();

  useEffect(() => {
    if (generatedOutput && formType === FormType.BLOG) {
      setSuccessMessage(
        'Almost there? Highlight sections you want to regenerate!'
      );
    }
  }, [generatedOutput]);

  useEffect(() => {
    if (generatedOutput && formType === FormType.PERSONABLOG) {
      setSuccessMessage(
        'Almost there? Highlight sections you want to regenerate!'
      );
    }
  }, [generatedOutput]);

  useEffect(() => {
    setResponseRated(false);
  }, [editedOutput]);

  useEffect(() => {
    if (successMessage) {
      const closeTimeout = setTimeout(() => {
        setSuccessMessage('');
      }, 10 * 1000);
      return () => clearTimeout(closeTimeout);
    }
  }, [successMessage]);

  useEffect(() => {
    if (!editTextSelected) {
      setRevisePopupPosition(undefined);
    }
  }, [editTextSelected]);

  if (
    !blogInput ||
    !personaBlogInput ||
    !emailInput ||
    !personaEmailInput ||
    !socialInput ||
    !personaSocialInput ||
    !personaMarketingIdeasInput ||
    !personaNewsletterInput ||
    !tiktokCaptionInput ||
    !marketingIdeasInput ||
    !contentImproverInput ||
    !audienceRetargetInput ||
    !featureDescriptionInput ||
    !personaFeatureDescriptionInput ||
    !callToActionInput ||
    !personaCallToActionInput ||
    !heroHeaderInput ||
    !personaHeroHeaderInput ||
    !subheaderInput ||
    !personaSubheaderInput ||
    !audienceChangerInput ||
    !formatChangerInput ||
    !toneChangerInput ||
    !pasFrameworkInput ||
    !seasonalHolidayInput ||
    !discountPromotionInput ||
    !giveawayPromotionInput ||
    !linkedinCompanyBioInput ||
    !youtubeChannelDescriptionInput ||
    !youtubeVideoDescriptionInput ||
    !youtubeVideoScriptInput ||
    !shortVideoScriptInput ||
    !productDescriptionInput ||
    !valuePropInput ||
    !brandMissionInput
  )
    return null;

  const insertText = async () => {
    if (!editTextSelected) return;
    setLoading(true);
    let values = {};
    let apiEndpoint = '/api/openai/';
    switch (formType) {
      case FormType.BLOG:
        values = blogInput;
        apiEndpoint += '/generateBlog';
        break;
      case FormType.PERSONABLOG:
        values = personaBlogInput;
        apiEndpoint += '/generatePersonaBlog';
        break;
      case FormType.EMAIL:
        values = emailInput;
        apiEndpoint += '/generateEmail';
        break;
      case FormType.PERSONAEMAIL:
        values = personaEmailInput;
        apiEndpoint += '/generatePersonaEmail';
        break;
      case FormType.SOCIAL:
      default:
        values = socialInput;
        apiEndpoint += '/generateSocial';
        break;
      case FormType.PERSONA:
        values = personaSocialInput;
        apiEndpoint += '/generatePersonaSocial';
        break;
      case FormType.TIKTOK:
        values = tiktokCaptionInput;
        apiEndpoint += 'generateTikTokCaption';
        break;
      case FormType.MARKETINGIDEAS:
        values = marketingIdeasInput;
        apiEndpoint += '/generateMarketingIdeas';
        break;
      case FormType.PERSONAMARKETINGIDEAS:
        values = personaMarketingIdeasInput;
        apiEndpoint += '/generatePersonaMarketingIdeas';
        break;
      case FormType.PERSONA_NEWSLETTER:
        values = personaNewsletterInput;
        apiEndpoint += 'generatePersonaNewsletter';
        break;
      case FormType.PROMOTIONAL_NEWSLETTER:
        values = personaNewsletterInput;
        apiEndpoint += 'generatePersonaNewsletter';
        break;
      case FormType.PRODUCT_UPDATE_NEWSLETTER:
        values = personaNewsletterInput;
        apiEndpoint += 'generatePersonaNewsletter';
        break;
      case FormType.EDUCATIONAL_NEWSLETTER:
        values = personaNewsletterInput;
        apiEndpoint += 'generatePersonaNewsletter';
        break;
      case FormType.ORGANIZATIONAL_NEWSLETTER:
        values = personaNewsletterInput;
        apiEndpoint += 'generatePersonaNewsletter';
        break;
      case FormType.CONTENT_IMPROVER:
        values = contentImproverInput;
        apiEndpoint += '/improveContent';
        break;
      case FormType.CONTENT_CONCISER:
        values = contentImproverInput;
        apiEndpoint += '/improveContent';
        break;
      case FormType.CONTENT_ENGAGER:
        values = contentImproverInput;
        apiEndpoint += '/improveContent';
        break;
      case FormType.CONTENT_EXPANDER:
        values = contentImproverInput;
        apiEndpoint += '/improveContent';
        break;
      case FormType.AUDIENCE_RETARGET:
        values = audienceRetargetInput;
        apiEndpoint += '/retargetAudience';
        break;

      case FormType.AUDIENCE_CHANGER:
        values = audienceChangerInput;
        apiEndpoint += '/generateAudienceChanger';
        break;
      case FormType.FORMAT_CHANGER:
        values = formatChangerInput;
        apiEndpoint += '/generateFormatChanger';
        break;
      case FormType.TONE_CHANGER:
        values = toneChangerInput;
        apiEndpoint += '/generateToneChanger';
        break;
      case FormType.SEASONAL:
        values = seasonalHolidayInput;
        apiEndpoint += '/generateSeasonalHoliday';
        break;
      case FormType.DISCOUNT:
        values = discountPromotionInput;
        apiEndpoint += '/generateDiscountPromotion';
        break;
      case FormType.GIVEAWAY:
        values = giveawayPromotionInput;
        apiEndpoint += '/generateGiveAwayPromotion';
        break;
      case FormType.LINKEDIN_COMPANY_BIO:
        values = linkedinCompanyBioInput;
        apiEndpoint += '/generateLinkedInCompanyBio';
        break;
      case FormType.YOUTUBE_CHANNEL_DESCRIPTION:
        values = youtubeChannelDescriptionInput;
        apiEndpoint += '/generateYoutubeChannelDescription';
        break;
      case FormType.PRODUCT_DESCRIPTION:
        values = productDescriptionInput;
        apiEndpoint += '/generateProductDescription';
        break;
      case FormType.VALUE_PROP:
        values = valuePropInput;
        apiEndpoint += '/generateValueProp';
        break;
      case FormType.YOUTUBE_VIDEO_DESCRIPTION:
        values = youtubeVideoDescriptionInput;
        apiEndpoint += '/generateYoutubeVideoDescription';
        break;
      case FormType.BRAND_MISSION:
        values = brandMissionInput;
        apiEndpoint += '/generateBrandMission';
        break;
      case FormType.YOUTUBE_VIDEO_SCRIPT:
        values = youtubeVideoScriptInput;
        apiEndpoint += '/generateYoutubeVideoScript';
        break;
      case FormType.SHORT_VIDEO_SCRIPT:
        values = shortVideoScriptInput;
        apiEndpoint += '/generateShortVideoScript';
        break;
    }

    const prevResponse =
      editedOutput[0].text.substring(0, editTextSelected.start) +
      '[insert]' +
      editedOutput[0].text.substring(editTextSelected.end);

    try {
      const currOutputLength = prevResponse.length - '[insert]'.length;
      const { data: generatedResponseData } = await axios.post<
        GeneratedResponse[]
      >(apiEndpoint, {
        ...values,
        prevResponse,
        isInsert: true,
      });
      const output: GeneratedResponse[] =
        generatedResponseData.length > 0
          ? generatedResponseData
          : [
              {
                text: '⚠️ Something went wrong when generating your response, please try again.',
                canKeepGoing: false,
              },
            ];
      setEditedOutput(output);
      setGeneratedOutput(output);
      setCanKeepGoing(generatedResponseData[0].canKeepGoing);
      if (generatedOutput[0].text) {
        setEditTextSelected({
          ...editTextSelected,
          end:
            editTextSelected.start +
            generatedOutput[0].text.length -
            currOutputLength,
        });
      }
    } catch (error) {
      const err = error as AxiosError;
      const errorData = err.response?.data as APIErrors;
      const errorMessage =
        typeof errorData?.error === 'string' && errorData?.error
          ? errorData.error
          : 'Something went wrong, please try again';
      setEditedOutput([{ text: errorMessage, canKeepGoing: false }]);
      setGeneratedOutput([{ text: errorMessage, canKeepGoing: false }]);
    } finally {
      setLoading(false);
    }
  };
  const copyResponse = async (responseIndex = 0) => {
    if (!editedOutput[responseIndex]) return;
  
    // Fallback function to copy plain text using a temporary textarea
    const fallbackCopyText = (text: string) => {
      const textarea = document.createElement('textarea');
      document.body.appendChild(textarea);
      textarea.value = text;
      textarea.focus();
      textarea.select();
      try {
        const successful = document.execCommand('copy');
        console.log(
          'Fallback: Copying text command was ' +
            (successful ? 'successful' : 'unsuccessful')
        );
      } catch (err) {
        console.error('Fallback: Could not copy text: ', err);
      }
      document.body.removeChild(textarea);
    };
  
    // The main function that generates and writes multiple formats
    const copyClicked = async () => {
      // Get the original text from your edited output
      const originalText = editedOutput[responseIndex].text;
      console.log('Original text:', originalText);
  
      // Generate HTML from the original text using your marked parser
      const markText = await marked(originalText);
      console.log('HTML version:', markText);
  
      // For plain text we simply use the original text.
      const plainText = originalText;
  
      // Create a very basic RTF representation.
      // For better formatting, you may need a proper HTML/Markdown to RTF converter.
      const escapeRtf = (text: string): string => {
        // Escape backslashes and curly braces for RTF format
        return text.replace(/\\/g, '\\\\').replace(/{/g, '\\{').replace(/}/g, '\\}');
      };
      const rtfText = `{\\rtf1\\ansi\\deff0{\\fonttbl{\\f0 Arial;}} ${escapeRtf(plainText)}}`;
  
      // Create Blob objects for each format with the proper MIME type.
      const blobPlain = new Blob([plainText], { type: 'text/plain' });
      const blobHTML = new Blob([markText], { type: 'text/html' });
      const blobRTF = new Blob([rtfText], { type: 'text/rtf' });
  
      // Bundle them into one ClipboardItem.
      const clipboardItem = new ClipboardItem({
        'text/plain': blobPlain,
        'text/html': blobHTML,
        'text/rtf': blobRTF
      });
  
      try {
        // Write the ClipboardItem to the clipboard
        await navigator.clipboard.write([clipboardItem]);
        console.log('Copied to clipboard with plain text, HTML, and RTF!');
      } catch (err) {
        console.error('Failed to copy to clipboard: ', err);
        // Fall back to copying just the plain text.
        fallbackCopyText(plainText);
      }
    };
  
    try {
      // Check if the Clipboard API with multiple-format writing is available.
      if (navigator.clipboard && typeof navigator.clipboard.write === 'function') {
        await copyClicked();
      } else {
        // If Clipboard API is not fully supported, fall back to writing plain text.
        const correctedText = editedOutput[responseIndex].text
            .replace(/\\n/g, '\n')
            .replace(/\\"/g, '"');
        await navigator.clipboard.writeText(correctedText).catch((err) => {
          console.error('Failed to copy as text, trying manual copy method: ', err);
          fallbackCopyText(correctedText);
        });
      }
    } catch (err) {
      console.error(err);
    } finally {
      setSuccessMessage('Copied!');
    }
  };
  

  const saveFileClicked = (id: string) => {
    setFileToBeSaved(id);
    setShowFileTree(true);
  };

  const reasoningClicked = async (id: string) => {
    if (!id) return;
    try {
      setLoading(true);

      const response = await axios.post(
        '/api/openai/content/createContentReasoning',
        {
          id,
        }
      );
      const RC = (response.data as string) ?? 'an error has occurred';
      const index = editedOutput.findIndex((response) => response.id === id);
      editedOutput[index].reasoning = RC;
      setEditedOutput([...editedOutput]);
      setReasoningID(id);
      setShowReasoning(true);
      return RC;
    } catch (err) {
      console.log(err);
      return 'an error occurred';
    } finally {
      setLoading(false);
    }
  };

  return (
    <OutputAreaContext.Provider
      value={{
        responseRated,
        setResponseRated,
        successMessage,
        setSuccessMessage,

        editTextSelected,
        setEditTextSelected,
        textareaScrollOffset,
        setTextareaScrolloffset,
        revisePopupPosition,
        setRevisePopupPosition,

        insertText,
        showFileTree,
        setShowFileTree,
        fileToBeSaved,
        setFileToBeSaved,
        showReasoning,
        setShowReasoning,
        reasoningID,
        setReasoningID,
        copyResponse,
        saveFileClicked,
        reasoningClicked,
      }}
    >
      {children}
    </OutputAreaContext.Provider>
  );
};

export const useOutputAreaContext = (): OutputAreaContextInterface => {
  const context = useContext(OutputAreaContext);
  if (context === undefined) {
    throw new Error(
      'OutputAreaContext must be within OutputAreaContextProvider'
    );
  }

  return context;
};
