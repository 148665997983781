import Image from 'next/image';
import Link from 'next/link';
import { FC, useState, useEffect } from 'react';
import { BasicButton } from '../buttons/BasicButton';
import styles from './BasicNavbar.module.css';
import { useAuthContext } from '../../../contexts/AuthContext';
import router, { useRouter } from 'next/router';

const navbarLinks: { text: string; href: string }[] = [
  {
    text: 'Features',
    href: '/#features',
  },
  {
    text: 'Try Me',
    href: '/#demo',
  },
  {
    text: 'Pricing',
    href: '/pricing',
  },
];

type LoginButtonProps = {
  extraLarge?: boolean;
};

type FreeTrialButtonProps = {
  extraLarge?: boolean;
};

export const BasicNavbar: FC = () => {
  const router = useRouter();
  const { signOutUser, attemptedSignIn } = useAuthContext();
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const [showBasicNavbar, setShowBasicNavbar] = useState(true);
  const [showAppSumoNavbar, setShowAppSumoNavbar] = useState(false);
  const [showLogoNavbar, setShowLogoNavbar] = useState(false);
  const [showLogOutNavBar, setShowLogOutNavbar] = useState(false);

  useEffect(() => {
    if (router.pathname.startsWith('/signin/onboarding')) {
      setShowBasicNavbar(false);
      setShowLogoNavbar(true);
    }
  }, []);

  useEffect(() => {
    if (router.pathname.startsWith('/signin/checkout')) {
      setShowBasicNavbar(false);
      setShowLogOutNavbar(true);
    }
  }, [router]);

  useEffect(() => {
    if (router.pathname.startsWith('/appsumo')) {
      setShowBasicNavbar(false);
      setShowAppSumoNavbar(true);
    }
  }, []);

  const LoginButton: FC<LoginButtonProps> = ({ extraLarge }) => {
    return (
      <h2
        onClick={() => {
          loginButtonRedirect();
        }}
      >
        <a>
          <BasicButton
            extraLarge={extraLarge}
            theme="light"
            type="button"
            text="Log In"
            disabled={false}
            onClick={() => {}}
          />
        </a>
      </h2>
    );
  };

  const LogOutButton: FC<LoginButtonProps> = ({ extraLarge }) => {
    return (
      <h2
        onClick={() => {
          handleSignOutAndRedirect();
        }}
      >
        <a>
          <BasicButton
            extraLarge={extraLarge}
            theme="dark"
            type="button"
            text="Sign Out"
            disabled={false}
            onClick={() => {}}
          />
        </a>
      </h2>
    );
  };

  const FreeTrialButton: FC<FreeTrialButtonProps> = ({ extraLarge }) => {
    return (
      <h2
        onClick={() => {
          FreeTrialButtonRedirect();
        }}
      >
        <a>
          <BasicButton
            extraLarge={extraLarge}
            theme="dark"
            type="button"
            text="Try it for free"
            rightImage={{
              src: '/icons/ArrowRightWhite.svg',
              height: 24,
              width: 24,
            }}
            disabled={false}
            onClick={() => {}}
          />
        </a>
      </h2>
    );
  };

  const handleSignOutAndRedirect = async () => {
    await signOutUser();
    // await router.push('https://www.app.supercopy.ai');
  };

  const loginButtonRedirect = async () => {
    await signOutUser();
    await router.push('/signin');
  };

  const FreeTrialButtonRedirect = async () => {
    await signOutUser();
    await router.push('/freetrial');
  };
  return (
    <nav className={styles['container']}>
      {showBasicNavbar && attemptedSignIn && router.route !== '/signin' && (
        <div className={styles['inner-container']}>
          <div className={styles['name-container']}>
            {/* <Link href="/signout"> */}
            <h2
              onClick={() => {
                handleSignOutAndRedirect();
              }}
            >
              <Link href="/">
                <Image
                  src="/icons/New_SC_LOGO.png"
                  alt="Close"
                  layout="fixed"
                  objectFit="contain"
                  height={40}
                  width={170}
                />
              </Link>
            </h2>
            {/* </Link> */}
          </div>
          {/* <div className={styles['links-container']}>
          {navbarLinks.map(({ text, href }) => {
            return (
              <Link key={href} href={href}>
                <a>{text}</a>
              </Link>
            );
          })}
        </div> */}
          <div className={styles['login-button']}>
            <div>
              <LoginButton />
            </div>
            <div>
              <FreeTrialButton />
            </div>
          </div>
          {/* <div className={styles['menu-button']}>
          <BasicButton
            theme="light"
            type="button"
            text="Menu"
            disabled={false}
            onClick={() => {
              setShowMobileMenu(!showMobileMenu);
            }}
          />
        </div> */}
          {/* {showMobileMenu && (
          <div
            className={styles['mobile-menu']}
            onClick={() => setShowMobileMenu(false)}
          >
            <button className={styles['close-button']}>
              <Image
                src={'/icons/closeBlack.svg'}
                alt=""
                objectFit="contain"
                layout="fixed"
                height={24}
                width={24}
              />
            </button>
            {navbarLinks.map(({ text, href }) => {
              return (
                <Link key={href} href={href}>
                  <a onClick={() => setShowMobileMenu(false)}>
                    <h1>{text}</h1>
                  </a>
                </Link>
              );
            })}
            <LoginButton extraLarge />
          </div>
        )} */}
        </div>
      )}
      {showLogoNavbar && (
        <div className={styles['inner-container']}>
          <div className={styles['name-container']}>
            {/* <Link href="/signout"> */}
            <h2
              onClick={() => {
                handleSignOutAndRedirect();
              }}
            >
              <Link href="/">
                <Image
                  src="/icons/New_SC_LOGO.png"
                  alt="Close"
                  layout="fixed"
                  objectFit="contain"
                  height={40}
                  width={170}
                />
              </Link>
            </h2>
            {/* </Link> */}
          </div>
        </div>
      )}
      {showLogOutNavBar && (
        <div className={styles['inner-container']}>
          <div className={styles['name-container']}>
            {/* <Link href="/signout"> */}
            <h2
              onClick={() => {
                handleSignOutAndRedirect();
              }}
            >
              <Link href="/">
                <Image
                  src="/icons/New_SC_LOGO.png"
                  alt="Close"
                  layout="fixed"
                  objectFit="contain"
                  height={40}
                  width={170}
                />
              </Link>
            </h2>
            {/* </Link> */}
          </div>
          <div className={styles['login-button']}>
            <div>
              <LogOutButton />
            </div>
          </div>
        </div>
      )}
      {showAppSumoNavbar && (
        <div className={styles['inner-container']}>
          <div className={styles['name-container']}>
            {/* <Link href="/signout"> */}
            <h2 onClick={() => {}}>
              <div className={styles['appsumo-container']}>
                <Image
                  src="/icons/New_SC_LOGO.png"
                  alt="Close"
                  layout="fixed"
                  objectFit="contain"
                  height={35}
                  width={150}
                />
                <div className={styles['appsumo-x']}>
                  <Image
                    src="/icons/Close X.svg"
                    alt="Close"
                    layout="fixed"
                    objectFit="contain"
                    height={15}
                    width={15}
                  />
                </div>
                <Image
                  src="/images/AppSumo_Dark_logo 1.svg"
                  alt="Close"
                  layout="fixed"
                  objectFit="contain"
                  height={23}
                  width={100}
                />
              </div>
            </h2>
          </div>
        </div>
      )}
    </nav>
  );
};
